import React, { FC } from "react"

import styled from "styled-components"

interface ItemListProps {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  > :not(:first-child) {
    margin-top: 10px;
  }
`

const Item = styled.div`
  background-color: ${({ theme }) => theme.colors.secoundary};
  border-radius: 10px;
  width: 100%;
  max-width: 865px;
  padding: 10px;
  > p {
    color: #fff;
    text-align: center;
    font-size: 18px;
  }
`

const list = [
  "Wykłady live (on-line)",
  "Dostęp do archiwum",
  "Testy on-line",
  "Aplikację mobilną",
  "Kontakt z ekspertem w dziedzinie ruchu drogowego",
  "Wsparcie kursana poza wykładami",
]

export const ItemList: FC<ItemListProps> = () => {
  return (
    <Container>
      {list.map((e, i) => (
        <Item key={i}>
          <p>{e}</p>
        </Item>
      ))}
    </Container>
  )
}

export default ItemList
